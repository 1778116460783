import {
  motion,
  useScroll,
  useTransform,
} from "framer-motion";
import { useRef } from "react";
import { Config } from "../../config/config";
import "./frame.css";

function useParallax(value, distance) {
  return useTransform(value, [0, 1], [-distance, distance]);
}

const Frame = ({ project, previousTitle, nextTitle, onClick, }) => {
  const ref = useRef(null);
  const { scrollYProgress } = useScroll({ target: ref });
  const y = useParallax(scrollYProgress, 0);

  return (
    <div className="frame-section" ref={ref} onClick={onClick} >
      <div>
        <div className="project-image-container">
         <div
            className="project-image-border"
          >
            <img
              className="project-image"

              src={Config.api.mediaUrl + project.thumbnail}
              alt={project.title}
            />
          </div>
          <div className="project-image-overlay"></div>
        </div>
      </div>

      <motion.h2
        className="frame-title-secondary"
        style={{ fontSize: "50px", marginBottom: "20rem" }}
      >
        {previousTitle}
      </motion.h2>
      <motion.h2
        className="frame-title"
        style={{ y, fontSize: "50px", zIndex: 2, cursor: "pointer" }}
      >
        {project.title}
      </motion.h2>
      <motion.h2
        className="frame-title-secondary"
        style={{ fontSize: "50px", marginTop: "20rem"  }}
      >
        {nextTitle}
      </motion.h2>
    </div>
  );
};

export default Frame;
