import React, { useRef } from "react";
import { motion, useTransform } from "framer-motion";
import ReactMarkdown from "react-markdown";
import { useInView } from "react-intersection-observer";
import "./studio-creation.css";

const StudioCreation = ({ textes }) => {
  const [ref, inView] = useInView({
    threshold: 0.5, // Change this value to adjust the threshold for when the component is considered in view
    triggerOnce: true, // Only trigger the animation once
  });

  const titleVariants = {
    hidden: { y: -50, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: { delay: 0.5, duration: 0.5, ease: "easeOut" },
    },
  };

  const descriptionVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: { delay: 2, duration: 0.5, ease: "easeOut" },
    },
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: { duration: 0.5, ease: "easeOut", staggerChildren: 0.2 },
    },
  };

  return (
    <div className="">
      <motion.div
        className="mx-auto w-screen h-screen bg-cover min-h-[1000px] bg bg-no-repeat"
        ref={ref}
        variants={containerVariants}
        animate={inView ? "visible" : "hidden"}
      >
        <motion.p
          className="pl-[20rem] w-3/6 h-full flex flex-col items-start justify-center text-white inline-flex"
          style={{ fontFamily: "Lexend" }}
          variants={titleVariants}
          initial={{ opacity: 0 }}
          animate={ inView ? {opacity: 1} : {opacity: 0 }}
          transition={{ delay: 0.5, duration: 0.5, ease: "easeIn" }}
        >
          <ReactMarkdown
            className="lg:text-9xl md:text-8xl leading-none mb-4 text-white ml-10"
            escapeHtml={false}
            components={{
              strong: ({ node, ...props }) => (
                <span className="text-strong" {...props} />
              ),
              em: ({ node, ...props }) => (
                <span className="text-strong text-outline" {...props} />
              ),
            }}
          >
            {textes?.studioCreationTitle}
          </ReactMarkdown>
        </motion.p>
        <motion.div
          className="relative w-4/6 h-full flex flex-col pl-[10%] text-left items-start justify-center text-white inline-flex overflow-hidden max-w-[33%]"
          style={{ fontFamily: "Lexend", transform: "translateY(-5%)" }}
          variants={descriptionVariants}
          initial={{ opacity: 0 }}
          animate={ inView ? {opacity: 1} : {opacity: 0 }}
          transition={{ delay: 2, duration: 0.5, ease: "easeIn" }}
        >
          <ReactMarkdown
            className="text-3xl leading-relaxed text-white"
            escapeHtml={false}
            style={{ fontFamily: "Lexend" }}
            components={{
              strong: ({ node, ...props }) => (
                <span className="text-strong text-[15pt]" {...props} />
              ),
            }}
          >
            {textes?.studioCreationLongDescription}
          </ReactMarkdown>
        </motion.div>
      </motion.div>
    </div>
  );
};

export default StudioCreation;
