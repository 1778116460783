import { Config } from "../config/config";

const baseUrl = Config.api.url;
const APP_TOKEN = Config.api.token;

async function get(path, token) {
  const url = `${baseUrl}${path}`;
  const response = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }
  return response.json();
}

async function fetchProjects() {
  return get("projects?populate=thumbnail,brand", APP_TOKEN);
}


async function fetchTextes() {
  return get("textes", APP_TOKEN);
}

async function fetchBrands() {
  return get("brands?populate=logo", APP_TOKEN);
}

async function fetchAgencies() {
  return get("agencies?populate=logo", APP_TOKEN);
}

async function fetchAssociations() {  
  return get("associations?populate=logo", APP_TOKEN);
}


// async function fetchConversations(token) {
//   return get("conversations?populate=users", token);
// }

// async function fetchConversationById(id, token) {
//   return get(`conversations/${id}?populate[messages][populate][from][populate]=profile_pic&populate[messages][populate][to][populate]=profile_pic&populate=users`, token);
// }

export { get, fetchProjects, fetchTextes, fetchBrands, fetchAgencies, fetchAssociations };