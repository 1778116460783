import {
  motion,
  useScroll,
  useTransform,
  useMotionValueEvent,
  AnimatePresence,
} from "framer-motion";
import { useRef } from "react";
import { RandomBubbles } from "./random-bubbles/random-bubbles";
import ReactMarkdown from "react-markdown";
import { BubbleAnimation } from "../../components/bubble";
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedin,
  FaArrowDown,
} from "react-icons/fa";
import Round from "../../Round.svg";

import "./sam-logo.css";
import { useEffect } from "react";

export const SAMLogo = ({ textes, hideScroll }) => {
  const targetRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: targetRef,
    offset: ["start end", "end end"],
  });
  const backgroundOpacity = useTransform(
    scrollYProgress,
    [0.15, 0.2, 0.3, 0.5, 1],
    [0, 0.8, 0.8, 0.9, 1]
  );
  const backgroundRotation = useTransform(scrollYProgress, [0, 1], [0, 360]);

  const finalTextOpacity = useTransform(
    scrollYProgress,
    [0.1, 0.2, 0.6, 0.8, 0.9],
    [0, 0.5, 1, 1, 1]
  );
  const stayConnectedOpacity = useTransform(
    scrollYProgress,
    [0.1, 0.2, 0.6, 0.7, 0.9],
    [0, 0, 0, 0.1, 1]
  );

  const emailOpacity = useTransform(
    scrollYProgress,
    [0.1, 0.2, 0.6, 0.9, 0.98],
    [0, 0, 0, 0.01, 1]
  );

  const span1PosX = useTransform(
    scrollYProgress,
    [0.15, 0.12, 0.2, 0.25],
    ["-800%", "-400%", "-200%", "75%"]
  );
  const span1PosY = useTransform(
    scrollYProgress,
    [0.15, 0.15, 0.2, 0.25],
    ["-400%", "-200%", "-100%", "30%"]
  );
  const span1TextSize = useTransform(
    scrollYProgress,
    [0.15, 0.15, 0.2, 0.25],
    ["2000px", "1800px", "900px", "320px"]
  );

  const span2PosX = useTransform(
    scrollYProgress,
    [0.15, 0.22, 0.3, 0.45],
    ["100%", "150%", "180%", "165%"]
  );
  const span2PosY = useTransform(
    scrollYProgress,
    [0.15, 0.22, 0.3, 0.45],
    ["-400%", "-200%", "-100%", "30%"]
  );
  const span2TextSize = useTransform(
    scrollYProgress,
    [0.15, 0.22, 0.3, 0.45],
    ["2000px", "1800px", "900px", "320px"]
  );

  const span3PosX = useTransform(
    scrollYProgress,
    [0.15, 0.2, 0.35, 0.55],
    ["900%", "500%", "400%", "185%"]
  );
  const span3PosY = useTransform(
    scrollYProgress,
    [0.15, 0.2, 0.35, 0.55],
    ["-400%", "-200%", "-100%", "30%"]
  );
  const span3TextSize = useTransform(
    scrollYProgress,
    [0.15, 0.2, 0.35, 0.55],
    ["2000px", "1800px", "900px", "320px"]
  );

  const stayConnectedX = useTransform(
    scrollYProgress,
    [0.7, 0.75, 0.8, 0.81],
    ["30%", "30%", "30%", "30%"]
  );
  const stayConnectedY = useTransform(
    scrollYProgress,
    [0.7, 0.75, 0.8, 0.81],
    ["700%", "600%", "500%", "400%"]
  );

  const contactX = useTransform(
    scrollYProgress,
    [0.7, 0.75, 0.8, 0.81],
    ["60%", "60%", "60%", "60%"]
  );

  const contactY = useTransform(
    scrollYProgress,
    [0.7, 0.75, 0.8, 0.81],
    ["350%", "350%", "350%", "400%"]
  );

  useMotionValueEvent(scrollYProgress, "change", (value) => {
    if (value > 0.8) {
      hideScroll(true);
    } else if (value < 0.8) hideScroll(false);
  });

  return (
    <motion.section
      id="contact"
      ref={targetRef}
      className="flex h-[200vh] items-start justify-start"
    >
      <div className="logo-div sticky min-h-[100vh] min-w-[80rem] whitespace-nowrap before:absolute pb-[50vh] mb-[10vh]">
        <motion.div
          style={{
            opacity: backgroundOpacity,
            rotate: backgroundRotation,
            backgroundImage: `url(${Round})`,
          }}
          className="absolute mt-50 inset-0 bg-contain bg-no-repeat bg-center max-h-[80rem] max-w-[80rem] z-0"
        />
        <motion.span
          style={{
            opacity: finalTextOpacity,
            x: span1PosX,
            y: span1PosY,
            fontFamily: "SAM",
            color: "white",
            fontSize: span1TextSize,
          }}
          className="sam-logo-text"
        >
          S
        </motion.span>
        <motion.span
          style={{
            opacity: finalTextOpacity,
            x: span2PosX,
            y: span2PosY,
            fontFamily: "SAM",
            color: "white",
            fontSize: span2TextSize,
          }}
          className="sam-logo-text"
        >
          A
        </motion.span>
        <motion.span
          style={{
            opacity: finalTextOpacity,
            x: span3PosX,
            y: span3PosY,
            // fontSize: "320px",
            fontFamily: "SAM",
            color: "white",
            fontSize: span3TextSize,
          }}
          className="sam-logo-text"
        >
          M
        </motion.span>
        <motion.span
          style={{
            opacity: stayConnectedOpacity,
            alignContent: "center",
            alignSelf: "center",
            display: "flex",
            x: stayConnectedX,
            y: stayConnectedY,
            color: "white",
            fontFamily: "Lexend",
          }}
          // className="absolute"
        >
          <ReactMarkdown
            className="text-white text-7xl text-center mt-10"
            escapeHtml={false}
            components={{
              strong: ({ node, ...props }) => (
                <span className="text-strong" {...props} />
              ),
            }}
          >
            {textes?.stayConnected.replace("\n", `\n`)}
          </ReactMarkdown>
        </motion.span>
        <motion.a
          className="absolute border-[#00FFCC] border-2 rounded-full p-10 bottom-[40%]"
          href={"mailto:" + textes?.contactEmail.replace(/\*\*/g, "")}
          whileHover={{
            scale: 1.3,
            transition: { duration: 0.2, ease: "easeIn", type: "spring" },
          }}
          style={{
            opacity: emailOpacity,
            alignContent: "center",
            alignSelf: "center",
            display: "flex",
            x: contactX,
            y: contactY,
            color: "white",
            fontFamily: "Lexend",
          }}
        >
          <BubbleAnimation>
            <ReactMarkdown
              className="text-white text-5xl"
              escapeHtml={false}
              style={{
                fontFamily: "Lexend !important",
              }}
              components={{
                strong: ({ node, ...props }) => (
                  <span className="text-strong" {...props} />
                ),
              }}
            >
              {textes?.contactEmail}
            </ReactMarkdown>
          </BubbleAnimation>
        </motion.a>
        <RandomBubbles align={"left"} />
        <RandomBubbles align={"right"} />
      </div>
      <div
        id="footer"
        className="footer-custom justify-between whitespace-nowrap w-screen"
      >
        <motion.span className="flex-1 text-left ml-10 mb-10 text-4xl text-black">
          <motion.span className="bg-[#00FFCC] border-1 border-[#00FFCC] rounded-1 p-5 max-w-[450px]">
            On recommence ? <FaArrowDown className="inline" />
          </motion.span>
        </motion.span>
        <motion.div
          className="flex-1 text-center text-white inline-flex justify-center items-center"
          style={{
            fontFamily: "Lexend",
          }}
        >
          <BubbleAnimation>
            <motion.a
              className="text-5xl pl-5 flex"
              href={textes?.facebookLink}
              target="_blank"
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
            >
              <FaFacebookF />
            </motion.a>
          </BubbleAnimation>
          <BubbleAnimation>
            <motion.a
              className="text-5xl pl-5 flex"
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              href={textes?.instagramLink}
              target="_blank"
            >
              <FaInstagram />
            </motion.a>
          </BubbleAnimation>
          <BubbleAnimation>
            <motion.a
              className="text-5xl pl-5 flex"
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              href={textes?.linkedinLink}
              target="_blank"
            >
              <FaLinkedin />
            </motion.a>
          </BubbleAnimation>
        </motion.div>
        <motion.span
          className="flex-1 text-right mr-10 mb-10"
          style={{
            fontFamily: "Lexend",
          }}
        >
          <BubbleAnimation>
            <ReactMarkdown
              className="text-white text-4xl"
              escapeHtml={false}
              components={{
                strong: ({ node, ...props }) => (
                  <span className="text-strong" {...props} />
                ),
              }}
              style={{
                fontFamily: "Lexend",
              }}
            >
              {textes?.allRightsReserved + new Date().getFullYear()}
            </ReactMarkdown>
          </BubbleAnimation>
        </motion.span>
      </div>
    </motion.section>
  );
};

export default SAMLogo;
