import React from "react";
import ReactMarkdown from "react-markdown";
import { motion } from "framer-motion"; // import framer-motion
import "./not-for-mobile.css";
import Round from "../../Round.svg";

export const NotForMobile = ({ textes }) => {
  return (
    <div style={{ fontFamily: "Lexend" }}>
      <ReactMarkdown
        className="text-white text-5xl text-left w-full max-w-full leading-tight relative"
        escapeHtml={false}
        components={{
          strong: ({ node, ...props }) => (
            <span
              className="text-strong text-5xl md:text-6xl lg:text-7xl"
              {...props}
            />
          ),
        }}
      >
        {textes?.NotForMobile}
      </ReactMarkdown>
      <p
        className="text-left text-2xl"
        style={{ fontFamily: "Lexend", filter: "blur(1px)" }}
      >
        (pour le moment)
      </p>

      <div
        className="relative bottom-0 align-center mb-10 mt-10 top-10"
        style={{ position: "relative" }}
      >
        <motion.img
          src={Round}
          alt="Round"
          className="mb-10 mt-10"
          style={{ rotate: "0deg" }}
          animate={{ rotate: "360deg" }}
          transition={{ duration: 10, repeat: Infinity }}
        />
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            width: "100%",
            transform: "translate(-50%, -50%)",
            marginTop: "-1.5rem",
          }}
        >
          <ReactMarkdown
            className="text-center text-white text-2xl md:text-6xl lg:text-7xl "
            escapeHtml={false}
            components={{
              strong: ({ node, ...props }) => (
                <span
                  className="text-strong text-3xl md:text-7xl lg:text-8xl"
                  {...props}
                />
              ),
            }}
          >
            {textes?.availableSoon}
          </ReactMarkdown>
        </div>
      </div>

      <h1
        className="text-white text-9xl text-center absolute bottom-0 left-1/2 transform -translate-x-1/2 mb-5"
        style={{ fontFamily: "SAM" }}
      >
        SAM
      </h1>
    </div>
  );
};

export default NotForMobile;
