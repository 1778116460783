import { motion } from "framer-motion";
import { useEffect, createElement, useState } from "react";
import ReactPlayer from "react-player";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Config } from "../../../config/config";
import { BubbleAnimation } from "../../../components/bubble";
import "./project-popup.css";
import ReactMarkdown from "react-markdown";

const YoutubeSlide = ({ url, playing }) => (
  <ReactPlayer
    className="carousel-player"
    width="100%"
    height="100%"
    url={url.url}
    playing={playing}
    controls
    style={{ paddingBottom: "1%" }}
  />
);

export const ProjectPopup = ({ isOpen, onClose, project }) => {
  const variants = {
    open: { opacity: 1, display: "flex" },
    closed: { opacity: 0, display: "none" },
  };

  const customRenderItem = (item, props) =>
    createElement(item.type, { ...item.props, ...props });

  const getVideoThumb = (videoId) =>
    `https://img.youtube.com/vi/${videoId}/default.jpg`;

  const getVideoId = (url) =>
    url.substr("https://www.youtube.com/embed/".length, url.length);

  const customRenderThumb = (children) =>
    children.map((item) => {
      const videoId = getVideoId(item.props.url.url);
      return <img src={getVideoThumb(videoId)} />;
    });

  const [isPlaying, setIsPlaying] = useState(false);

  const handleClose = () => {
    setIsPlaying(false);
    onClose();
  };

  useEffect(() => {
    if (isOpen === false) {
      setIsPlaying(false);
    }
  }, [isOpen]);

  return (
    project && (
      <motion.div
        className={`popup fixed top-0 left-0 w-full h-full bg-black bg-opacity-75 z-50 -translate-y-1/2 translate-y-centered-offset ${
          isOpen ? "" : "hidden"
        }`}
        initial="closed"
        animate={isOpen ? "open" : "closed"}
        variants={variants}
      >
        <div className="container mx-auto w-full h-full flex flex-col justify-center items-center">
          <motion.div className="bg-black rounded-lg p-8 w-full h-full">
            <BubbleAnimation triggerOnce={false}>
              <img
                className="mx-auto my-4 rounded-xl m-auto mb-10"
                src={Config.api.mediaUrl + project.brand}
                alt={project.title}
                width={150}
                height={150}
              />
            </BubbleAnimation>
            <Carousel
              showThumbs={true}
              dynamicHeight={true}
              renderItem={customRenderItem}
              renderThumbs={customRenderThumb}
              className="carousel-custom"
            >
              {project.youtube_urls.map((video, index) => (
                <YoutubeSlide
                  key={"youtube-" + index + project.id}
                  url={video}
                  playing={isPlaying}
                  className="carousel-player"
                />
              ))}
            </Carousel>
            <div className="flex flex-row justify-center items-center mt-[5%]">
              <br />
              <ReactMarkdown
                className="text-white mt-10 text-2xl text-left w-[100vw] leading-tight"
                escapeHtml={false}
                components={{
                  strong: ({ node, ...props }) => (
                    <span className="text-strong text-[15pt]" {...props} />
                  ),
                }}
              >
                {project.description}
              </ReactMarkdown>
            </div>
            <button
              className="absolute top-0 right-0 m-4 text-white text-2xl font-bold cursor-pointer"
              onClick={handleClose}
            >
              &#10005;
            </button>
          </motion.div>
        </div>
      </motion.div>
    )
  );
};

export default ProjectPopup;
