import { useScroll, useTransform, motion } from "framer-motion";
import {
  useEffect,
  useLayoutEffect,
  useMemo,
  memo,
  useRef,
  useState,
} from "react";
import { SentencesOnScroll } from "../../components/sentences-scroll";
import { useInView } from "react-intersection-observer";
import "./sam-introduction.css";
import Round from "../../Round.svg";

const SAMIntroduction = ({ textes }) => {
  const targetRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: targetRef,
    offset: ["start end", "end end"],
  });
  const [ref, inView] = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });
  
  const animationOrder = useMemo(
    () => ({
      initial: 0,
      fadeInEnd: 0.2,
      showParagraphOne: 0.35,
      hideParagraphOne: 0.6,
      showParagraphTwoStart: 0.7,
      showParagraphTwoEnd: 0.8,
      hideParagraphTwo: 1,
    }),
    []
  );

  const [paragraph1OpacityValue, setParagraph1OpacityValue] = useState(0);
  const [paragraph2OpacityValue, setParagraph2OpacityValue] = useState(0);

  const paragraph1Opacity = useTransform(
    scrollYProgress,
    [
      animationOrder.fadeInEnd + 0.02,
      animationOrder.showParagraphOne,
      animationOrder.hideParagraphOne,
    ],
    [0, 1, 0]
  );
  const paragraph1TranslateY = useTransform(
    scrollYProgress,
    [
      animationOrder.fadeInEnd + 0.02,
      animationOrder.showParagraphOne,
      animationOrder.hideParagraphOne,
    ],
    ["1rem", "0rem", "-1rem"]
  );

  const paragraph2Opacity = useTransform(
    scrollYProgress,
    [
      animationOrder.showParagraphTwoStart,
      animationOrder.showParagraphTwoEnd,
      animationOrder.hideParagraphTwo,
    ],
    [0, 1, 0]
  );
  const paragraph2TranslateY = useTransform(
    scrollYProgress,
    [
      animationOrder.showParagraphTwoStart,
      animationOrder.showParagraphTwoEnd,
      animationOrder.hideParagraphTwo,
    ],
    ["1rem", "0rem", "-1rem"]
  );

  const span1PosX = useTransform(
    scrollYProgress,
    [0, 0.2, 1],
    ["0%", "-70%", "-100%"]
  );
  const span1PosY = useTransform(
    scrollYProgress,
    [0, 0.2, 1],
    ["0%", "-70%", "-100%"]
  );

  const span2PosX = useTransform(
    scrollYProgress,
    [0, 0.2, 1],
    ["0%", "0%", "0%"]
  );
  const span2PosY = useTransform(
    scrollYProgress,
    [0, 0.2, 1],
    ["0%", "-400%", "-500%"]
  );

  const span3PosX = useTransform(
    scrollYProgress,
    [0, 0.2, 1],
    ["0%", "70%", "100%"]
  );
  const span3PosY = useTransform(
    scrollYProgress,
    [0, 0.2, 1],
    ["0%", "-70%", "-100%"]
  );

  const blur = useTransform(
    scrollYProgress,
    [0, 0.2, 1],
    ["blur(0px)", "blur(10px)", "blur(20px)"]
  );

  const bulletOpacity = useTransform(scrollYProgress, [0, 0.01, 1], [1, 0, 0]);

  const position = useTransform(scrollYProgress, (pos) =>
    pos >= 1 ? "relative" : "fixed"
  );

  useLayoutEffect(() => {
    setParagraph1OpacityValue(paragraph1Opacity.get());
    setParagraph2OpacityValue(paragraph2Opacity.get());
  }, [paragraph1Opacity, paragraph2Opacity]);

  useEffect(() => {
    function updateOpacity() {
      setParagraph1OpacityValue(paragraph1Opacity.get());
      setParagraph2OpacityValue(paragraph2Opacity.get());
    }

    const sub1 = paragraph1Opacity.on("change", updateOpacity);
    const sub2 = paragraph2Opacity.on("change", updateOpacity);

    return () => {
      sub1();
      sub2();
    };
  }, [paragraph1Opacity, paragraph2Opacity]);

  return (
    <section id="intro">
      <div className="sam-intro-container sticky h-[100vh]">
        <div className="sam-title-container" ref={ref}>
          <motion.img
            src={Round}
            width={"50%"}
            animate={{ rotate: 360 }}
            transition={{ repeat: Infinity, duration: 6 }}
            className={"sam-background-logo"}
            exit={{ opacity: 0 }}
          />
          <motion.h1
            className="sam-title"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.5 }}
          >
            SAM
          </motion.h1>
          <motion.div
            className="sam-leftTitle"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.9 }}
          >
            <span className="sam-leftTitle-text">Studio</span>
            <br />
            <span className="sam-leftTitle-text">
              <b>animé</b>
            </span>
            <br />
            <span className="sam-leftTitle-text">
              et <b>mouvementé</b>
            </span>
          </motion.div>
        </div>
        <div className="sam-text-container">
          <motion.span
            style={{ x: span1PosX, y: span1PosY, filter: blur }}
            className="sam-text"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: 1.5 }}
          >
            {textes?.mentra1}
          </motion.span>
          <motion.span
            style={{ opacity: bulletOpacity }}
            className="sam-bullet"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: 1.5 }}
          >
            &#8226;
          </motion.span>
          <motion.span
            style={{ x: span2PosX, y: span2PosY, filter: blur }}
            className="sam-text"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: 1.5 }}
          >
            {textes?.mentra2}
          </motion.span>
          <motion.span
            style={{ opacity: bulletOpacity }}
            className="sam-bullet"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: 1.5 }}
          >
            &#8226;
          </motion.span>
          <motion.span
            style={{ x: span3PosX, y: span3PosY, filter: blur }}
            className="sam-text"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: 1.5 }}
          >
            {textes?.mentra3}
          </motion.span>
        </div>
      </div>
      <div className="relative h-[400vh]" ref={targetRef}>
        <div className="sticky flex origin-center justify-center">
          <motion.div
            style={{
              opacity: paragraph1Opacity,
              "--y": paragraph1TranslateY,
              position,
              whiteSpace: "pre-wrap",
            }}
            className="centered-text top-1/2 flex origin-center -translate-y-1/2 translate-y-centered-offset w-[300px] pr-16 flex items-center justify-center h-screen w-screen leading-tight text-white text-center text-sm md:text-sm lg:text-sm"
          >
            {textes?.intro1 && (
              <SentencesOnScroll
                sentences={textes?.intro1.split("\n")}
                isVisible={paragraph1OpacityValue}
              />
            )}
          </motion.div>
          <motion.div
            style={{
              opacity: paragraph2Opacity,
              "--y": paragraph2TranslateY,
              position,
              whiteSpace: "pre-wrap",
            }}
            className="centered-text top-1/2 flex origin-center -translate-y-1/2 translate-y-centered-offset w-[300px] pr-16 flex items-center justify-center h-screen w-screen text-sm md:text-sm lg:text-sm leading-tight text-white text-center"
          >
            {textes?.intro2 && (
              <SentencesOnScrollMemoized
                sentences={textes?.intro2.split("\n")}
                isVisible={paragraph2OpacityValue}
              />
            )}
          </motion.div>
        </div>
      </div>
    </section>
  );
};

const SentencesOnScrollMemoized = memo(SentencesOnScroll);

export default SAMIntroduction;
