import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import "./nav-bar.css";

const NavBar = ({ textes }) => {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <motion.nav
      initial={{ y: -200 }}
      animate={{ y: isScrolled ? 0 : -200 }}
      transition={{ duration: 0.5 }}
      className="fixed top-0 left-0 w-full bg-black bg-opacity-10 z-50 flex justify-center items-center nav-bar"
    >
      <div className="container mx-auto flex items-center justify-between py-4 px-6">
        <div className="flex items-center nav-bar-logo">
          <h1 className="text-9xl text-white">SAM</h1>
        </div>
        <ul className="flex items-center ml-auto">
          <li className="mx-5 nav-bar-item">
            <a href={"#" + textes?.navBarItem2}>{textes?.navBarItem2}</a>
          </li>
          <li className="mx-5 nav-bar-item">
            <a href={"#" + textes?.navBarItem1}>{textes?.navBarItem1}</a>
          </li>
        </ul>
      </div>
    </motion.nav>
  );
};

export default NavBar;
