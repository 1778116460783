import { motion, AnimatePresence } from "framer-motion";
import ReactMarkdown from "react-markdown";

const Sentence = ({ sentence, steps, idx, isVisible  }) => {

  return (
    <AnimatePresence>
      {isVisible && (
      <motion.p
        style={{ y: 20 }}
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0, transition: { delay: steps[idx] * 2 } }}
        exit={{
          opacity: 0,
          y: -20,
          transition: { duration: steps[idx] * 0.2 },
        }}
      >
         <ReactMarkdown
            className="text-white lg:text-8xl md:text-7xl text-center"
            escapeHtml={false}
            components={{
              strong: ({ node, ...props }) => (
                <span className="text-strong" {...props} />
              ),
            }}
          >{sentence}</ReactMarkdown>
      </motion.p>
      )}
    </AnimatePresence>
  );
};

export default Sentence;
