import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { Config } from "../../config/config";
import {
  fetchBrands,
  fetchAgencies,
  fetchAssociations,
} from "../../utils/requests";
import ReactMarkdown from "react-markdown";
import "./wework.css";

const WeWork = ({ textes, type }) => {
  const [data, setData] = useState([]);

  const getDatas = () => {
    const lowerType = type;

    switch (lowerType) {
      case "brands":
        fetchBrands().then((data) => setData(data.data));
        break;
      case "agencies":
        fetchAgencies().then((data) => setData(data.data));
        break;
      case "associations":
        fetchAssociations().then((data) => setData(data.data));
        break;
      default:
        return "";
    }
  };

  useEffect(() => {
    getDatas();
  }, []);

  const logos = data.map((item) => ({
    id: item.id,
    alt: item.attributes.name,
    src:
      Config.api.mediaUrl +
      item.attributes.logo.data.attributes.formats.thumbnail.url,
  }));

  return (
    logos.length > 0 && (
      <div className="container mx-auto w-screen h-screen">
        {/* <h1 className="text-4xl font-bold mb-8 text-white">We work with {type.type}</h1> */}
        <div className="text-8xl text-left font-bold text-white wework-title">
          <ReactMarkdown
            escapeHtml={false}
            components={{
              strong: ({ node, ...props }) => (
                <span className="centered-text-strong" {...props} />
              ),
            }}
          >
            {(type === "brands" && textes?.weworkBrands) ||
              (type === "agencies" && textes?.weworkAgencies) ||
              (type === "associations" && textes?.weworkAssociations)}
          </ReactMarkdown>
        </div>
        <div className="grid grid-cols-7 gap-5 grid-border">
          {logos.map((logo) => (
            <motion.img
              key={logo.id}
              src={logo.src}
              alt={logo.alt}
              className={`w-50 h-50 mg-0 pd-0`}
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
            />
          ))}
        </div>
      </div>
    )
  );
};

export default WeWork;
