const config = {
  api: {
    // url: "http://localhost:1337/api/",
    // mediaUrl: "http://localhost:1337",
    url: "https://api.studio-sam.fr/api/",
    mediaUrl: "https://api.studio-sam.fr",
    // token: "7a5ebc8fdac4fabf4c0cc7bbfa8829923c6879d550442d9dbdcf731bfcf3c594d94e8a9ebcfaf0bc73e1b3cecf915ae5259499ca3e1468aa653c371aeba35ac953fc6fa69765401b3294c001466667db31d0c8817fcf9b025646077d105c7b7a6dcba0b3222c50264eb16f352cc48f1b2c4307b76eb0131ea4da5f236e9977d1",
    token:
      "ccda303c56845ac81194960992d81ad28bc15fa2647c72d85955357b40b13da854efe0f8d71c41b1098c0db2f7c876aaf6240414d1cc922635635f3ef949d355448eaf9a22c6dd6db9a1b4f98bd94ce6ce0b1845ecd6cdfca88d287d0fb471900d4dc7306049c5fd940c97cc658835701ac75fe2fc75c39be61bec1ff829ac98",
  },
};

export const Config =  config;