import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";

export const RandomBubbles = ({ align }) => {
  const [bubbles, setBubbles] = useState([]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const newBubbles = [...bubbles];
      if (newBubbles.length < 5) {
        const size = Math.floor(Math.random() * 50) + 10;
        const x = align === "left" ? Math.floor(Math.random() * window.innerWidth / 2) : Math.floor(Math.random() * window.innerWidth / 2) + window.innerWidth / 2;
        const y = Math.floor(Math.random() * window.innerHeight);
        if (x + size < document.body.offsetWidth && y + size < window.innerHeight) {
          newBubbles.push({
            id: Date.now(),
            size,
            opacity: 0,
            x,
            y,
          });
          setBubbles(newBubbles);
        }
      }
    }, 5000);

    return () => clearInterval(intervalId);
  }, [bubbles, align]);

  const handleAnimationComplete = (id) => {
    const newBubbles = bubbles.filter((bubble) => bubble.id !== id);
    setBubbles(newBubbles);
  };

  return (
    <div className={`absolute h-screen w-screen ${align === "left" ? "left-0" : "right-0"} ${align === "left" ? "ml-[80%]" : "mr-[80%]"}`} >
      <div className="relative h-full w-full">
        {bubbles.map((bubble) => (
          <motion.div
            key={bubble.id}
            className="absolute rounded-full bg-[#00ffcc]"
            style={{ width: bubble.size, height: bubble.size, opacity: bubble.opacity, left: bubble.x, top: bubble.y, filter: "blur(5px)" }}
            animate={{ opacity: [0, 1, 1, 0.5, 0], scale: [1, 2, 1], duration: 5 }}
            transition={{ duration: 5, repeat: Infinity }}
            onAnimationComplete={() => handleAnimationComplete(bubble.id)}
          />
        ))}
      </div>
    </div>
  );
};

export default RandomBubbles;