import logo from "./logo.svg";
import { SAMText } from "./sections/sam-text/sam-text";
import { SAMLogo } from "./sections/sam-logo/sam-logo";
import SAMIntroduction from "./sections/introduction/sam-introduction";
import "./App.css";
import ProjectGallery from "./sections/projects/project-gallery";
import { fetchTextes } from "./utils/requests";
import { useEffect, useRef, useState } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { motion } from "framer-motion";
import { NotForMobile } from "./components/not-for-mobile/not-for-mobile";
import Round from "./Round.svg";
import NavBar from "./components/nav-bar/nav-bar";
import ScrollToTopButton from "./components/scroll-to-top";
import StudioCreation from "./sections/studio-creation/studio-creation";

import WeWork from "./sections/wework/wework";

function App() {
  // flashlight cursor
  const [textes, setTextes] = useState();
  const [loading, setLoading] = useState(true);
  const [percentage, setPercentage] = useState(0);
  const [screenSize, setScreenSize] = useState(window.innerWidth);
  const [hideScroll, setHideScroll] = useState(false);

  const getTextes = () => {
    const result = fetchTextes();

    result.then((results) => {
      const tmpTextes = results.data.reduce((acc, texte) => {
        switch (texte.attributes.name) {
          case "intro1":
            return { ...acc, intro1: texte.attributes?.value };
          case "intro2":
            return { ...acc, intro2: texte.attributes.value };
          case "mentra1":
            return { ...acc, mentra1: texte.attributes.value };
          case "mentra2":
            return { ...acc, mentra2: texte.attributes.value };
          case "mentra3":
            return { ...acc, mentra3: texte.attributes.value };
          case "wework-brands":
            return { ...acc, weworkBrands: texte.attributes.value };
          case "wework-agencies":
            return { ...acc, weworkAgencies: texte.attributes.value };
          case "wework-associations":
            return { ...acc, weworkAssociations: texte.attributes.value };
          case "text-scrolling":
            return { ...acc, textScrolling: texte.attributes.value };
          case "stay-connected":
            return { ...acc, stayConnected: texte.attributes.value };
          case "contact-email":
            return { ...acc, contactEmail: texte.attributes.value };
          case "all-rights-reserved":
            return { ...acc, allRightsReserved: texte.attributes.value };
          case "instagram-link":
            return { ...acc, instagramLink: texte.attributes.value };
          case "linkedin-link":
            return { ...acc, linkedinLink: texte.attributes.value };
          case "facebook-link":
            return { ...acc, facebookLink: texte.attributes.value };
          case "random-words":
            return { ...acc, randomWords: texte.attributes.value };
          case "meta-title":
            return { ...acc, metaTitle: texte.attributes.value };
          case "meta-description":
            return { ...acc, metaDescription: texte.attributes.value };
          case "meta-tags":
            return { ...acc, metaTags: texte.attributes.value };
          case "meta-author":
            return { ...acc, metaAuthor: texte.attributes.value };
          case "loading-screen":
            return { ...acc, loadingScreen: texte.attributes.value };
          case "not-for-mobile":
            return { ...acc, NotForMobile: texte.attributes.value };
          case "nav-bar-item-1":
            return {
              ...acc,
              navBarItem1: texte.attributes.value.toLowerCase(),
            };
          case "nav-bar-item-2":
            return {
              ...acc,
              navBarItem2: texte.attributes.value.toLowerCase(),
            };
          case "studio-creation-title":
            return { ...acc, studioCreationTitle: texte.attributes.value };
          case "studio-creation-long-description":
            return {
              ...acc,
              studioCreationLongDescription: texte.attributes.value,
            };
          case "available-soon":
            return { ...acc, availableSoon: texte.attributes.value };
          default:
            return acc;
        }
      }, {});
      setTextes(tmpTextes);
    });
  };

  useEffect(() => {
    const interval = setInterval(() => {
        setPercentage((prevPercentage) => {
            // S'assurer que la valeur ne dépasse jamais 100
            const nextPercentage = prevPercentage + 1;
            return nextPercentage > 100 ? 100 : nextPercentage;
        });
    }, 10);

    setTimeout(() => {
      clearInterval(interval);
      // Directement définir à 100 pour éviter toute confusion
      setPercentage(100);
      setTimeout(() => {
        setLoading(false);
      }, 500);
    }, 1000);

    // enable({ size: "15vmax" }); // Cette ligne est commentée car elle contient une erreur de syntaxe

    return () => clearInterval(interval);
  }, [textes]);

  useEffect(() => {
    const handleResize = () => {
      console.log("changeScreenSize");
      setScreenSize(window.innerWidth);
    };

    if (textes === undefined) getTextes();

    document.addEventListener("mousemove", update);
    document.addEventListener("touchmove", update);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      document.removeEventListener("mousemove", update);
      document.removeEventListener("touchmove", update);
    };
  }, []);

  const update = (e) => {
    var x = e?.clientX === 0 ? 1 : e?.clientX || e?.touches[0]?.clientX;
    var y = e?.clientY === 0 ? 1 : e?.clientY || e?.touches[0]?.clientY;

    requestAnimationFrame(() => {
      document.documentElement.style.setProperty("--cursorX", x + "px");
      document.documentElement.style.setProperty("--cursorY", y + "px");
    });
  };

  return (
    <HelmetProvider>
      {textes?.metaDescription && (
        <Helmet>
          <meta name="description" content={textes?.metaDescription} />
          <meta name="keywords" content={textes?.metaTags} />
          <meta name="author" content={textes?.metaAuthor} />
          <title>{textes?.metaTitle}</title>
          <meta property="og:title" content={textes?.metaTitle} />
          <meta property="og:image" content="%PUBLIC_URL%/logo.svg" />
          <meta property="og:description" content={textes?.metaDescription} />
          <meta property="og:url" content="https://studio-sam.fr" />
          <meta property="og:image:width" content="1200" />
          <meta property="og:image:height" content="627" />
        </Helmet>
      )}

      <div className="App">
        {screenSize < 1440 && loading === false && (
          <div className="text-center text-white text-4xl bg-black p-20 h-[100vh] w-screen ">
            <NotForMobile textes={textes} />
          </div>
        )}
        {screenSize > 1300 && loading === false && textes && (
          <>
            <NavBar textes={textes} />
            <motion.div
              initial={{ rotate: 270, opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 2, delay: 1.8, repeat: Infinity }}
              style={{
                position: "fixed",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "white",
                fontSize: "25pt",
                fontFamily: "Arial Unicode MS",
                bottom: "1%",
                left: "47%",
                fontStretch: "expanded",
                visibility: hideScroll ? "hidden" : "visible",
              }}
            >
              〈〈
            </motion.div>

            <ScrollToTopButton />
            <div className="snap-proximity">
              {/* <div className="snap-start snap-normal"> */}
              <SAMIntroduction textes={textes} />
              {/* </div> */}
              {/* <div className="snap-center snap-normal"> */}
              <SAMText textes={textes} />
              {/* </div> */}
              <ProjectGallery />
              <StudioCreation textes={textes} />
              <WeWork type="brands" textes={textes} />
              <WeWork type="agencies" textes={textes} />
              <WeWork type="associations" textes={textes} />
              <SAMLogo textes={textes} hideScroll={setHideScroll} />
            </div>
          </>
        )}
        {loading && (
          <>
            <motion.div
              className="relative App-loading flex flex-col items-center justify-center"
              animate={{ opacity: percentage < 100 ? 1 : 0.1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.5 }}
            >
              <motion.img
                src={Round}
                width={"800px"}
                animate={{ rotate: 360, scale: percentage / 100 }}
                transition={{ repeat: Infinity, duration: 6 }}
                alt="logo-round"
              />
              <motion.div
                className="text-white text-9xl text-center"
                animate={{ scale: 0.8 }}
                transition={{ duration: 3 }}
                style={{
                  fontFamily: "Lexend",
                  position: "absolute",
                }}
              >
                {percentage + "%"}
              </motion.div>
            </motion.div>
          </>
        )}
      </div>
    </HelmetProvider>
  );
}

export default App;
