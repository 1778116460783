import { useScroll, AnimatePresence , motion } from "framer-motion";
import ReactMarkdown from "react-markdown";
import { useRef, useState, useEffect } from "react";
import Sentence from "./sentence";

export const SentencesOnScroll = ({ sentences,  isVisible }) => {
  const [steps, setSteps] = useState([]);
  const targetRef = useRef(null);

  const { scrollYProgress } = useScroll({
    target: targetRef,
    offset: ["start start", "end end"],
  });

  const generateSteps = (sentences, indexF) => {
    const steps = [];
    const nbOfSentences = sentences?.length;

    sentences?.forEach((sentence, index) => {
      if (nbOfSentences === 2 && index === 1) {
        steps.push(0.5);
        steps.push(1);
        return;
      }
      if (index === sentences.length - 1) {
        steps.push(1);
      } else if (indexF && index === indexF) {
        steps.push(0.5);
      } else {
        steps.push(index / (nbOfSentences - 1));
      }
    });
    return steps;
  };

  useEffect(() => {
    if (sentences?.length) {
      const steps = generateSteps(sentences);
      setSteps(steps);
    }
  }, [sentences]);
  
  return (
    <div key={'scrollable-text-'+ sentences.length * 2} >
      <div className="sticky flex origin-center justify-center" key={'ref-scrollable-text-'+ sentences.length * 2}>
        <motion.div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            height: "50vh",
            width: "70vw",
          }}
        >
          {sentences?.map(
              (sentence, index) =>
                steps?.length > 0 && (
                    <Sentence
                      key={"sentence-" + index}
                      sentence={sentence}
                      steps={steps}
                      idx={index}
                      scrollYProgress={scrollYProgress}
                      delay={index * 0.5 + scrollYProgress * 2}
                      isVisible={isVisible}
                    />
                )
            )}
        </motion.div>
      </div>
    </div>
  );
};

export default SentencesOnScroll;