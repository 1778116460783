import { motion, useTransform, useScroll } from "framer-motion";
import { FaArrowUp } from "react-icons/fa";

const ScrollToTopButton = () => {
  const { scrollY } = useScroll();
  const y = useTransform(scrollY, [0, 200], [100, 0]);
  const opacity = useTransform(scrollY, [0, 200], [0, 1]);

  const handleClick = () => {
    const introElement = document.querySelector("#intro");
    introElement.scrollIntoView({ behavior: "smooth", block: "start", top: 0 });
  };

  return (
    <motion.button
      className="fixed bottom-0 left-0 mb-8 ml-32 p-2 bg-00FFCC text-white rounded-full shadow-lg"
      style={{ y, opacity, scale: 2, zIndex: 1000 }}
      onClick={handleClick}
    >
      <FaArrowUp className="inline" /> remonter en haut
    </motion.button>
  );
};

export default ScrollToTopButton;
