import { motion, useScroll, useTransform, useInView } from "framer-motion";
import { useRef } from "react";
import ReactMarkdown from "react-markdown";
import "./sam-text.css";

export const SAMText = ({ textes }) => {
  const targetRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: targetRef,
    offset: ["start end", "end end"],
  });
  const textPosX = useTransform(scrollYProgress, [0.1, 1], ["100%", "-170%"]);
  const textOpacity = useTransform(scrollYProgress, [0.5, 1], [1, 0.5]);

  const highlightTextPosX = useTransform(
    scrollYProgress,
    [0.1, 0.6, 1],
    ["20%", "50%", "60%"]
  );
  const higlightTextOpacity = useTransform(
    scrollYProgress,
    [0, 0.1, 0.7, 0.8, 0.97, 1],
    [0, 0, 0, 1, 1, 0]
  );

  const spanScale = useTransform(
    scrollYProgress,
    [0, 0.2, 0.8, 1],
    [1, 1.5, 1.5, 2]
  );

  const backgroundOpacity = useTransform(
    scrollYProgress,
    [0, 0.1, 0.7, 0.8, 0.9, 1],
    [0, 0, 0, 0.5, 1, 0]
  );

  const circleSize = useTransform(
    scrollYProgress,
    [0, 0.1, 0.7, 0.8, 0.9, 1],
    [0, 200, 500, 800, 1200, 15000]
  );

  const backgroundColor = "#00FFCC";

  return (
    <motion.section
      ref={targetRef}
      className="flex h-[300vh] items-start justify-start"
    >
      <motion.div
        style={{
          opacity: backgroundOpacity,
          backgroundColor: backgroundColor,
          visibility: "visible",
          width: circleSize,
          height: circleSize,
          borderRadius: "50%",
          position: "fixed",
          top: "50%",
          left: "50%",
          x: "-50%",
          y: "-50%",
        }}
        transition={{
          type: "spring",
          damping: 5,
          stiffness: 100,
          restDelta: 0.001,
          duration: 15,
          ease: [0, 0.71, 0.2, 1.01],
        }}
      />
      <div className="sticky top-1/2 left-1/2 min-h-[50rem] min-w-[50rem] -translate-x-1/2 -translate-y-1/2 whitespace-nowrap before:absolute inline-block">
        <motion.div
          aria-hidden
          style={{
            x: textPosX,
            y: "0%",
            fontFamily: "Lexend",
            opacity: textOpacity,
          }}
          className="whitepspace-prewrap min-w-screen absolute top-1/2 left-[calc(-50vw+25rem)] z-[11] text-[23rem] text-white [-webkit-text-stroke:1px var(--color-heading)]"
        >
          <ReactMarkdown
            escapeHtml={false}
            components={{
              strong: ({ node, ...props }) => (
                <span
                  className="centered-text-strong"
                  style={{ fontSize: "300pt" }}
                >
                  <motion.span
                    style={{
                      scale: spanScale,
                      x: highlightTextPosX,
                      opacity: higlightTextOpacity,
                    }}
                    className="text-white"
                    {...props}
                  />
                </span>
              ),
            }}
          >
            {textes?.textScrolling.split("*")[0]}
          </ReactMarkdown>
        </motion.div>
        <motion.span
          style={{
            x: highlightTextPosX,
            y: "0%",
            opacity: higlightTextOpacity,
          }}
          className="whitepspace-prewrap min-w-screen absolute top-[10vh] left-[calc(-50vw+25rem)] z-[11] text-[23rem] text-white [-webkit-text-stroke:1px var(--color-heading)]"
        >
          <ReactMarkdown
            escapeHtml={false}
            components={{
              strong: ({ node, ...props }) => (
                <span
                  className="centered-text-strong"
                  style={{
                    fontSize: "300pt",
                    color: "white",
                    zIndex: 13,
                    letterSpacing: "-20px",
                  }}
                  {...props}
                />
              ),
            }}
          >
            {textes?.textScrolling.slice(
              textes?.textScrolling.split("*")[0].length
            )}
          </ReactMarkdown>
        </motion.span>
      </div>
    </motion.section>
  );
};

export default SAMText;
