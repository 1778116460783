import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";

export const BubbleAnimation = ({ children, triggerOnce = true }) => {
  const containerVariants = {
    hidden: {
      opacity: 0,
      scale: 0,
    },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        duration: 0.5,
        ease: "easeInOut",
      },
    },
  };

  const contentVariants = {
    hidden: {
      opacity: 0,
      y: 50,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        ease: "easeInOut",
      },
    },
  };

  return (
    <InView triggerOnce={triggerOnce}>
      {({ inView, ref }) => (
        <motion.div
          ref={ref}
          variants={containerVariants}
          initial={inView ? "visible" : "hidden"}
          animate={inView ? "visible" : "hidden"}
        >
          <motion.div variants={contentVariants}>{children}</motion.div>
        </motion.div>
      )}
    </InView>
  );
};

export default BubbleAnimation;