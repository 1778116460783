import { useTransform, motion, useScroll } from "framer-motion";
import { useState, useRef, useEffect } from "react";
import { fetchProjects } from "../../utils/requests";
import { ProjectPopup } from "./project-popup/project-popup";
import Frame from "./frame";
import "./project-gallery.css";

const animationOrder = {
  initial: 0,
  fadeInEnd: 0.15,
  showParagraphOne: 0.25,
  hideParagraphOne: 0.3,
};

const ProjectGallery = () => {
  const targetRef = useRef(null);
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const { scrollYProgress } = useScroll({
    target: targetRef,
    offset: ["start end", "end end"],
  });

  const position = useTransform(scrollYProgress, (pos) => {
    if (pos >= 1) {
      return "relative";
    }
    return pos < 0.15 ? "relative" : "fixed";
  });

  const titleTranslateY = useTransform(
    scrollYProgress,
    [
      animationOrder.fadeInEnd + 0.02,
      animationOrder.showParagraphOne,
      animationOrder.hideParagraphOne,
    ],
    ["0rem", "0.5rem", "0.5rem"]
  );

  const loadProjects = () => {
    fetchProjects().then((data) => {
      const projects = data.data.map((project) => ({
        id: project.id,
        date: project.attributes.date,
        title: project.attributes.title,
        description: project.attributes.description,
        thumbnail: project.attributes.thumbnail.data.attributes.url,
        brand: project.attributes.brand.data.attributes.url,
        youtube_urls: project.attributes.youtube_urls
          ?.split("\n")
          .map((video) => ({
            url: video,
          })),
      }));
      setProjects(projects);
    });
  };

  const getPreviousTitle = (index) => {
    if (index === 0) {
      return null;
    }
    return projects[index - 1].title;
  };

  const getNextTitle = (index) => {
    if (index === projects.length - 1) {
      return null;
    }
    return projects[index + 1].title;
  };

  useEffect(() => {
    loadProjects();
  }, []);

  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = (project) => {
    setSelectedProject(project);
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    projects && (
      <div className="framer-section" ref={targetRef} id="projets">
        <motion.h1
          className="projects-title translate-y-centered-offset top-10% w-[300px] pl-16 text-2xl leading-tight text-white"
          style={{
            opacity: 1,
            top: titleTranslateY,
            position,
            position: "sticky", // Add this line to make the title sticky
          }}
          initial={{ y: 0 }}
          animate={{ y: titleTranslateY }}
          transition={{ duration: 0.3 }}
        >
          Projets
        </motion.h1>
        <ProjectPopup
          isOpen={isOpen}
          onClose={handleClose}
          project={selectedProject}
        />
        {projects.map((project, index) => (
          <Frame
            id={"projet-" + index}
            onClick={() => handleOpen(project)}
            project={project}
            key={project.id}
            previousTitle={getPreviousTitle(index)}
            nextTitle={getNextTitle(index)}
          />
        ))}
      </div>
    )
  );
};

export default ProjectGallery;
